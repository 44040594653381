import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import isMobileDevice from "../utils/isMobileDevice"
import SEO from "../components/seo"
import RegisterForm from "../components/register/register-form"
import Menu from "../components/shared/menu"
import Footer from "../components/shared/footer"
import styles from "../styles/register/register.module.scss"
import "../styles/base/index.css"

const RegisterPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [step,setStep]= useState("")

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local) navigate("/age-gate", { state: { newPath: "/" } })
  }, [])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Goose Island",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Register",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.gooseisland.com.ar/register",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: step ? `step_${step}` : "",
    })
  }, [step])

  useEffect(() => {
    setIsMobile(isMobileDevice())
  }, [])

  return (
    <div className={styles.registerContainer}>
      <SEO title="Goose Island" />
      <Menu isMobile={isMobile} showMenu={showMenu} setShowMenu={setShowMenu} />
      <RegisterForm
        isMobile={isMobile}
        success={false}
        setShowMenu={setShowMenu}
        setStep={setStep}
      />
      <Footer isMobile={isMobile} indexPage={false} />
    </div>
  )
}

export default RegisterPage
